import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NetworkStateService } from './services/network-state.service';

import { Router } from '@angular/router';
import { ConfigService } from './services/config.service';
import { AuthService } from './shared/services/auth.service';
import { SignalingService } from './shared/services/signaling.service';

@Component({
  selector: 'itl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isOnline = true;
  public offer: Observable<any>;
  public isAuth: boolean;
  public incomingCall: Observable<boolean>;
  public caller: Observable<string>;
  private _subscriptions = new Subscription();

  constructor(
    public authService: AuthService,
    private _networkStateService: NetworkStateService,
    private signalingService: SignalingService,
    private router: Router,
    private configService: ConfigService
  ) {
    this._subscriptions.add(
      this._networkStateService.observeOnlineState().subscribe(online => {
        this.isOnline = online;
      })
    );
    this.offer = signalingService.offer;
    this.incomingCall = signalingService.incomingCall;
    this.caller = signalingService.caller;
  }

  async ngOnInit(): Promise<void> {
    const config = await this.configService.getTenantConfig();
    if (config.videoChat?.enabled) {
      this._subscriptions.add(
        this.authService.authenticatedEventPublisher.subscribe(event => {
          if (event.isAuthenticated) {
            this.isAuth = true;
            void this.signalingService.connect();
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  async acceptIncomingCall(offer) {
    await this.signalingService.setAnsweredCall(offer);
    this.router.navigate(['/contact/video-chat']);
  }
}
