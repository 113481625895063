import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  tenantId: '70000',
  baseUrl: 'https://patient-app.k8s.api.halloalberta.de',
  amplitude: {
    enabled: true,
    apiKey: 'af54c1445e360af9da1b98d0aa591b55',
  },
  oauthConfig: {
    issuer: 'https://auth.halloalberta.de',
    redirectUri: window.location.origin,
    clientId: '87b050b7-f95b-463c-baef-06b846a1bdc8',
    responseType: 'code',
    scope: 'openid offline_access',
    showDebugInformation: true,
    clearHashAfterLogin: true,
  },
  register: {
    approvalContent:
      'Ich stimme der <a href="https://mediq.de/Nutzungsbedingungen-Mediq-Patienten-App">Nutzungsvereinbarung</a> zu und habe die <a href="https://mediq.de/uber-uns/datenschutz">Datenschutzerklärung</a> gelesen.',
    contactEmail: 'app@meinmediq.de',
  },
  signalingServer: 'https://signaling.k8s.api.halloalberta.de',
  pwaMoreInfoLink: 'https://www.mediq.com',
  legal: {
    title: 'Einwilligung für die Videoberatungsfunktion',
    body: `
      <div>Mit dem Klick auf die Schaltfläche „Einwilligen” erkläre ich mein Einverständnis zur Verarbeitung meiner
        personenbezogenen Daten, insbesondere meiner Gesundheitsdaten, für die Nutzung der Videoberatungsfunktion in der
        Alberta Patientenapp gegenüber der</div>
      <div>Mediq Deutschland GmbH</div>
      <div>In der Bruchwies 10</div>
      <div>66663 Merzig</div>
      <div>Tel.: 0800 33 44 800</div>
      <div>E-Mail: kundenservice@mediq.de</div><br />

      <div>Wenn Sie die Videoberatungsfunktion nicht nutzen möchten, klicken Sie bitte auf die Schaltfläche „Nicht
        einwilligen”.</div><br />

      <div><b>ZWECK DER EINWILLIGUNG</b></div>
      <div>Die Datenverarbeitung durch die Alberta Patientenapp dient Ihrer persönlichen Beratung und Betreuung. Im Rahmen
        der
        Alberta Patientenapp können wir Sie per Videoübertragung flexibel und ortsunabhängig zu Ihren Anliegen beraten.
        Durch
        die Videoberatung können wir unsere aktuellen Dienstleistungen auch digital anbieten und für Sie erweitern. Die
        Videoberatung und -übertragung findet vom Ablauf her wie der persönliche Besuch vor Ort statt. Wie auch bei einem
        persönlichen Besuch können Ihre Angehörigen an der Videoberatung teilnehmen.
        Wir weisen ausdrücklich darauf hin, dass wir während der Videoberatung keine Aufzeichnungen von Ihnen anfertigen.
        Während der Videoübertragung werden auch keine anderweitigen Aufnahmen wie beispielsweise Bildschirmmomentaufnahmen
        (Screenshots) getätigt.</div><br />

      <div><b>EMPFÄNGER DER DATEN</b></div>
      <div>Die aus der Videoberatung resultierenden personenbezogenen Daten über Ihre Person (z.B. Daten zu Ihrem
        gesundheitlichen Zustand, insbesondere Pflegezustand von Wunden und Verletzungen) werden mit anderen an der
        Versorgung
        Beteiligten (z.B. Ärzte, Pflegeeinrichtung, Pflegedienst, Krankenhaus, Apotheke, Lohnhersteller in der
        Arzneimittelversorgung) geteilt, soweit dies für Ihre Versorgung erforderlich ist.</div><br />

      <div><b>Freiwilligkeit und Widerruf der Einwilligung</b></div>
      <div>Die Datenverarbeitung erfolgt aufgrund Ihrer informierten Einwilligung gemäß Art. 9 Abs. 2 lit. a DSGVO.
        Ihre Einwilligung gilt ab dem Zeitpunkt Ihrer entsprechenden Zustimmung in der App. Soweit Sie Ihre Einwilligung
        nicht
        widerrufen, gilt sie zeitlich unbeschränkt.
        Ihre Einwilligung ist freiwillig. Sie können sie ohne Angabe von Gründen verweigern, ohne dass Sie deswegen
        Nachteile
        zu befürchten hätten. Es wird darauf hingewiesen, dass diese Einwilligung jederzeit unentgeltlich in der App mit
        Wirkung für die Zukunft widerrufen werden
        kann. Der Widerruf kann in der App selbst erfolgen. Klicken Sie dazu zuerst in der Startseite im Bereich Videochat
        auf
        die Schaltfläche „Zustimmung aufheben” und anschließend im auf die Schaltfläche „Nicht einwilligen”.</div>
        `,
    accept: 'Einwilligen',
    decline: 'Nicht einwilligen',
  },
};
