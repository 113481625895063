/* eslint @typescript-eslint/naming-convention: 0 */

export interface ICoreConfig {
  tabs?: {
    link: string;
    icon?: string;
    label: string;
  }[];
  contactTypes?: {
    callbackService?: boolean;
    chat_live?: boolean;
    chat_video?: boolean;
    contactData?: boolean;
  };
  contactData?: {
    openingHours?: string;
    phoneNumber?: string;
    faxNumber?: string;
    email?: string;
    faqLink?: string;
  };
  chat?: {
    chatAgentName?: string;
    offlineBox?: {
      header?: string;
      message?: string;
    };
  };
  videoChat?: {
    enabled: boolean;
  };
  callbackService?: {
    infoText?: string;
    timeSelectionItems?: string[];
    subjectSelectionItems?: string[];
  };
  order?: {
    showDeliveryDateInformation: boolean;
    deliveryOutboundText?: string;
  };
  businessHours?: {
    weekDays: string[];
    hours: {
      start: string;
      end: string;
    };
    holidays?: string[];
  };
}

export const defaultConfig: Partial<ICoreConfig> = {
  tabs: [
    {
      label: 'Patient',
      link: '/',
      icon: 'menu_dashboard',
    },
    {
      label: 'Bestellungen',
      link: '/orders',
      icon: 'menu_orders',
    },
    {
      label: 'Dokumente',
      link: '/documents',
      icon: 'menu_documents',
    },
    {
      label: 'Kontakt',
      link: '/contact',
      icon: 'menu_chat',
    },
  ],
  contactTypes: {
    callbackService: true,
    chat_live: true,
    chat_video: true,
    contactData: true,
  },
  contactData: {
    openingHours: 'Hier könnte Ihre Erreichbarkeit stehen',
    phoneNumber: 'Hier könnte Ihre Telefonnummer stehen',
    faxNumber: 'Hier könnte Ihre Faxnummer stehen',
    email: 'Hier könnte Ihre E-Mail-Adresse stehen',
    faqLink: 'Hier könnte Ihr Link stehen',
  },
  chat: {
    chatAgentName: 'Alberta',
    offlineBox: {
      header: 'Alle Mitarbeiter offline',
      message: 'Leider ist aktuell kein Kundenberater verfügbar. Bitte schreiben Sie uns zu einem anderen Zeitpunkt.',
    },
  },
  videoChat: {
    enabled: false,
  },
};
